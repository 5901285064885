import React, { useContext, useState, ReactElement } from "react";
import styled from "styled-components";
import { useLocation } from "wouter";
import clsx from "clsx";
import { MainContext } from "../contexts/MainContext";

import { createStyles, Theme, withStyles, WithStyles } from "@material-ui/core/styles";
import Drawer, { DrawerProps } from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import PeopleIcon from "@material-ui/icons/People";
import DnsRoundedIcon from "@material-ui/icons/DnsRounded";
import TimerIcon from "@material-ui/icons/Timer";
import ReceiptIcon from "@material-ui/icons/Receipt";
import MoneyIcon from "@material-ui/icons/MonetizationOn";
import CheckIcon from "@material-ui/icons/Check";
import QueryIcon from "@material-ui/icons/QueryBuilderRounded";

import ExternalLinkIcon from "@material-ui/icons/OpenInNew";
import DeploymentsIcon from "@mui/icons-material/AppRegistration";
import AnnouncementIcon from "@mui/icons-material/Campaign";
import { Omit } from "@material-ui/types";

import { Logout } from "./Auth";
import GlobalTheme from "../theme/styledComponentsTheme";
import useLocalStorage from "react-use-localstorage";
import { billingCredentials, isEmpty } from "./utils";

const StyledNavigator = styled(Drawer)`
  background-color: ${props => props.theme.colors.midBlue};
`;


const LogoutWrapper = styled.div`
  margin: 20px 0;
  padding: 0 20px 0 20px;
  button {
    width: 100%;
  }
`;

const styles = (theme: Theme) =>
  createStyles({
    categoryHeader: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      backgroundColor: "#2F4858"

    },
    categoryHeaderPrimary: {
      color: theme.palette.common.white,
    },
    item: {
      paddingTop: 1,
      paddingBottom: 1,
      color: "rgba(255, 255, 255, 0.7)",
      "&:hover,&:focus": {
        backgroundColor: "rgba(255, 255, 255, 0.08)",
      },
    },
    itemCategory: {
      backgroundColor: GlobalTheme.colors.darkBlue,
      boxShadow: "0 -1px 0 #404854 inset",
      paddingTop: theme.spacing(2.5),
      paddingBottom: theme.spacing(2.5),
    },
    firebase: {
      fontSize: 24,
      color: theme.palette.common.white,
    },
    itemActiveItem: {
      // color: "#4fc3f7",
    },
    itemPrimary: {
      fontSize: "inherit",
    },
    itemIcon: {
      minWidth: "auto",
      marginRight: theme.spacing(2),
    },
    divider: {
      marginTop: theme.spacing(2),
    },
  });

interface NavItemProps {
  $active?: boolean;
  $isParent?: boolean;
  padding?: string;
}

const NavItem = styled(ListItem) <NavItemProps>`
  padding-top: 1;
  padding-bottom: 1;
  color: rgba(255, 255, 255, 0.7);
  background-color: #2F4858;
  &:hover, &:focus: {
    background-color: rgba(255, 255, 255, 0.08);
  }
  ${props => props.$active && `
    background-color: rgba(255, 255, 255, 0.08);
  `}

  ${props => props.$isParent && `
    background-color: #2F4858
    `}

  ${props => props.padding && `
    padding: ${props.padding}
  `}
  `;

interface AccordionProps {
  open?: boolean;
}
const Accordion = styled.div<AccordionProps>`
  overflow: hidden;
  ${props => props.open ? `
    max-height: 1000px;
  `
    :
    `
    max-height: 0%;
  `
}
`;

type Category = {
  id: string;
  icon?: ReactElement;
  href?: string;
  children?: Category[];
  target?: string;
};

type Categories = {
  id: string;
  children: Category[];
}

type OpenAccordions = {
  [key: number]: number
}


export interface NavigatorProps extends Omit<DrawerProps, "classes">, WithStyles<typeof styles> { }

function Navigator(props: NavigatorProps) {
  const { classes, ...other } = props;
  const [location, setLocation] = useLocation();
  const { roles } = useContext(MainContext);
  const [openAcordions, setOpenAcordions] = useState<OpenAccordions>({});
  const [credentials] = useLocalStorage("credentials");

  const categories: Categories[] = [
    {
      id: "Dashboards",
      children: [
        /*{ id: "Dashboards link", icon: <MultilineChartTwoToneIcon />, href: "dashboard" },*/
        { id: "Deployments", icon: <DeploymentsIcon />, href: "deployments" },
        {
          id: "Loadtests", icon: <TimerIcon />, children: [
            { id: "Prompt", href: "loadtests/prompt" },
            { id: "Call", href: "loadtests/call" },
            { id: "TT92", href: "loadtests/tt92" },
            { id: "SipRec", href: "loadtests/siprec" },
            { id: "Sip2WebRTC", href: "loadtests/webrtc" }
          ]
        },
        ...(roles.includes("send-email-announcements") ? [
          { id: "Announce", icon: <AnnouncementIcon />, href: "announcements" }
        ] : [])
      ],
    },
    {
      id: "Provisioning",
      children: [
      ],
    },
  ];

  if (roles.includes("api-access")) {
    categories[1].children.push({
      id: "Manage users", icon: <PeopleIcon />, children: [
        { id: "Unassigned users", href: "users/unassigned" },
        { id: "Assigned users", href: "users/assigned" }
      ]
    });
    categories[1].children.push({
      id: "Manage tenants", icon: <DnsRoundedIcon />, children: [
        { id: "Create", href: "tenants/create" },
        { id: "Overview", href: "tenants/overview" },
        { id: "Recover", href: "tenants/recover" }
      ]
    });
  }

  categories.push({
    id: "Code Coverage", children: [
      {
        id: "Function Tests", children: [
          { id: "IVR", href: "coverage/ivr/coverage-function/lcov-report/index.html", target: "cc-f-ivr" },
          { id: "SIPRec", href: "coverage/siprec/coverage-function/lcov-report/index.html", target: "cc-f-siprec" },
          { id: "SIP", href: "coverage/sip/coverage-function/lcov-report/index.html", target: "cc-f-sip" }
        ]
      },
      {
        id: "Unit Tests", children: [
          { id: "IVR", href: "coverage/ivr/coverage/lcov-report/index.html", target: "cc-u-ivr" },
          { id: "SIPRec", href: "coverage/siprec/coverage/lcov-report/index.html", target: "cc-u-siprec" },
        ]
      },
      {
        id: "Combined", children: [
          { id: "IVR", href: "coverage/ivr/coverage-combined/lcov-report/index.html", target: "cc-c-ivr" },
          { id: "SIPRec", href: "coverage/siprec/coverage-combined/lcov-report/index.html", target: "cc-c-siprec" },
        ]
      }
    ]
  });

  if (!isEmpty(billingCredentials(credentials))) {
    categories.push({
      id: "Billing",
      children: [
        { id: "Report", href: "invoicing/report", icon: <ReceiptIcon /> },
        { id: "Pricelist", href: "invoicing/pricelist", icon: <MoneyIcon /> },
        { id: "Contracts", href: "invoicing/contract", icon: <CheckIcon /> },
        { id: "Cost simulator", href: "invoicing/simulator", icon: <QueryIcon /> }
      ]
    });
  }

  const isActive = (href?: string) => {
    if (href) {
      return location.includes(href);
    }
    return false;
  };

  const onClickAcordion = (input: OpenAccordions) => {
    let currOpen = { ...openAcordions };
    const key: number = Number(Object.keys(input)[0]);
    if (currOpen[key] === input[key]) {
      delete currOpen[key];
    } else {
      currOpen = { ...currOpen, ...input };
    }
    setOpenAcordions(currOpen);
  };

  const isAccordionOpen = (input: OpenAccordions, links?: (string | undefined)[]) => {
    const key: number = Number(Object.keys(input)[0]);
    let currentIncludedInLinks = false;
    // console.log("location", location, location.slice(1))
    if (links && location && location.slice(1)) {
      // console.log("location", location);
      currentIncludedInLinks = links.includes(location.slice(1));
    }
    return currentIncludedInLinks || openAcordions[key] === input[key];
  };


  return (
    <StyledNavigator variant="permanent" {...other}>
      <List disablePadding style={{height: "100%"}} >
        <ListItem className={clsx(classes.firebase, classes.item, classes.itemCategory)}>
          Operations Portal
        </ListItem>
        <NavItem
          key={"go-to-console"}
          button
          className={clsx(classes.item, classes.itemActiveItem)}
          onClick={() => {
            window.open("https://d-93670b870e.awsapps.com/start#/", "_blank");
          }}
          $active={false}
          $isParent={false}
        >
          <>

            <ListItemIcon className={classes.itemIcon}><ExternalLinkIcon /></ListItemIcon>
            <ListItemText
              classes={{
                primary: classes.itemPrimary,
              }}
            >
              AWS Console
            </ListItemText>
          </>
        </NavItem>
        {categories.map(({ id, children }, categoryIndex) => (
          <React.Fragment key={id}>
            <ListItem className={classes.categoryHeader}>
              <ListItemText
                classes={{
                  primary: classes.categoryHeaderPrimary,
                }}
              >
                {id}
              </ListItemText>
            </ListItem>
            {children.map(({ id: childId, icon, href, target, children: secondLevel }, index) => (<React.Fragment key={childId}>
              <NavItem
                key={childId}
                button
                className={clsx(classes.item, classes.itemActiveItem)}
                onClick={() => {

                  if (href) {
                    if (target) {
                      window.open(`/${href}`, target);
                    } else {
                      setLocation(`/${href}`, { replace: true });
                    }
                  }
                  onClickAcordion({ [categoryIndex]: index });
                }}
                $active={isActive(href)}
                $isParent={secondLevel && !!secondLevel.length}
              >
                <>

                  <ListItemIcon className={classes.itemIcon}>{icon}</ListItemIcon>
                  <ListItemText
                    classes={{
                      primary: classes.itemPrimary,
                    }}
                  >
                    {childId}
                  </ListItemText>
                </>
              </NavItem>
              <Accordion open={isAccordionOpen({ [categoryIndex]: index }, secondLevel?.map(scLv => scLv.href))} onClick={(e) => e.stopPropagation()}>
                {secondLevel && secondLevel.map(({ id: childId, icon, href }) =>
                  <NavItem
                    key={childId}
                    button
                    className={clsx(classes.item, classes.itemActiveItem)}
                    onClick={(e) => {
                      e.stopPropagation();
                      if (href) {
                        if (target) {
                          window.open(`/${href}`, target);
                        } else {
                          setLocation(`/${href}`, { replace: true });
                        }
                      }
                    }}
                    $active={isActive(href)}
                    $isParent={false}
                    padding="0 0 0 40px"
                  >
                    <>

                      <ListItemIcon className={classes.itemIcon}>{icon}</ListItemIcon>
                      <ListItemText
                        classes={{
                          primary: classes.itemPrimary,
                        }}
                      >
                        {childId}
                      </ListItemText>
                    </>
                  </NavItem>
                )}
              </Accordion>
            </React.Fragment>
            ))}

          </React.Fragment>
        ))}

        <LogoutWrapper>
          <Logout />
        </LogoutWrapper>
      </List>
    </StyledNavigator>
  );
}

export default withStyles(styles)(Navigator);
